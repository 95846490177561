<template>
  <div class="lottery-details">
    <van-nav-bar
      class="van-nav-bar--fixed"
      title="开奖记录"
      left-arrow
      @click-left="onClickLeft"
    ></van-nav-bar>

    <div class="lottery-details-body">
      <div class="hd-text">
        <div class="van-row">
          <div class="van-col van-col--14">
            {{ journal.lotto_name }} 第{{ journal.name }}期开奖结果
          </div>
          <div class="van-col van-col--10">
            <span>{{ kaijiang.datetime }}</span>
          </div>
        </div>
      </div>
      <div class="border-box">
        <div class="border-box-body">
          <div class="border-box-body-bd">
            <div class="van-row">
              <div
                class="van-col van-col--3"
                v-for="(item, index) in list"
                :key="item.id"
              >
                <div class="connect" v-if="index == 6">
                  <i
                    class="van-icon van-icon-plus"
                    style="color: rgb(172, 172, 172)"
                  ></i>
                </div>

                <div class="icon-text" v-else>
                  <div>
                    <span
                      :class="
                        item.bose_name == '绿波'
                          ? 'slot-icon bg-amage-3'
                          : item.bose_name == '红波'
                          ? 'slot-icon bg-amage-1'
                          : item.bose_name == '蓝波'
                          ? 'slot-icon bg-amage-2'
                          : 'slot-icon'
                      "
                      ><span>{{ item.haoma_name }}</span></span
                    >
                  </div>
                  <div class="slot-text">
                    {{ item.animal_name }}<span>/{{ item.five_name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!---->
        </div>
      </div>

      <div class="details-info">
        <div class="recommend" v-if="recommend">
          <div class="hd-text">第{{ journal.name }}期推荐</div>
          <div class="bd-info">
            <div class="van-row" v-for="item in recommend" :key="item.id">
              <div class="van-col van-col--18">
                {{ item.xiaoma }}:
                {{
                  item.xiaoma == "十码" ? item.shima_name : item.shengxiao_name
                }}
                <!-- <span>蛇</span><span>马</span><span>猪</span><span>狗</span><span>兔</span><span>猴</span><span>牛</span><span>虎</span><span>鸡</span> -->
              </div>
              <div class="van-col van-col--6" v-if="item.status == '1'">
                <div style="text-align: right"><label>中奖</label></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="recommend van-hairline--top"
          style="margin-top: 10px; padding-top: 10px"
        >
          <div class="bd-info">
            <div class="van-row">
              <div class="van-col van-col--18">
                特码: <span>{{ kaijiang.special }}</span>
              </div>
              <div class="van-col van-col--6">
                <div style="text-align: right">
                  <!---->
                </div>
              </div>
            </div>

            <div class="van-row">
              <div class="van-col van-col--18">
                正码:
                <span
                  >{{ kaijiang.normal1 }},{{ kaijiang.normal2 }},{{
                    kaijiang.normal3
                  }},{{ kaijiang.normal4 }},{{ kaijiang.normal5 }},{{
                    kaijiang.normal6
                  }}</span
                >
              </div>
              <div class="van-col van-col--6">
                <div style="text-align: right">
                  <!---->
                </div>
              </div>
            </div>

            <div class="van-row" v-for="(item, index) in list" :key="item.id">
              <div class="van-col van-col--18" v-if="index < 6">
                正{{
                  item.digit == 1
                    ? "一"
                    : item.digit == 2
                    ? "二"
                    : item.digit == 3
                    ? "三"
                    : item.digit == 4
                    ? "四"
                    : item.digit == 5
                    ? "五"
                    : item.digit == 6
                    ? "六"
                    : ""
                }}:
                <span
                  >{{ item.haoma_name }},{{ item.five_name }},{{
                    item.animal_name
                  }},{{ item.bose_name }},{{ item.poultrybeast_name }},{{
                    item.mantissa_name
                  }},{{ item.size_name }},{{ item.onetwo_name }}</span
                >
              </div>
              <div class="van-col van-col--18" v-if="index > 6">
                特码:
                <span
                  >{{ item.haoma_name }},{{ item.five_name }},{{
                    item.animal_name
                  }},{{ item.bose_name }},{{ item.poultrybeast_name }},{{
                    item.mantissa_name
                  }},{{ item.size_name }},{{ item.onetwo_name }}</span
                >
              </div>
              <div class="van-col van-col--6">
                <div style="text-align: right">
                  <!---->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      category_id: 1,
      year_id: 1,
      lotto_id: 2,
      active: "kaijiang",
      list: [],
      data: [],
      recommend: [],
      kaijiang: [],
      journal: [],
      loading: false,
      finished: false,
      iserror: false,
      refreshing: false,
      page: 0,
      message: "",
      value: "",
      iscolour: 0,
      id: 0,
    };
  },
  mounted() {
    //this.active='picture';
    this.id = parseInt(this.$route.query.id)
      ? parseInt(this.$route.query.id)
      : 1;
    this.lotto_id = parseInt(this.$route.query.lotto_id)
      ? parseInt(this.$route.query.lotto_id)
      : 2;
    this.getData();
  },
  methods: {
    onClickLeft() {
      // 返回上一级页面
      this.$router.back();
    },
    onClickRight() {
      this.$router.push({ path: "/kaijiang" });
    },
    getData() {
      this.$http
        .get("api/kaijiang/show?id=" + this.id)
        .then(function (response) {
          if (response.data.code == "ok") {
            this.list = response.data.data.list;
            this.list.splice(6, 0, {});
            this.recommend = response.data.data.recommend;
            this.kaijiang = response.data.data.kaijiang;
            this.journal = response.data.data.journal;

            this.loading = false;

            //this.finished = true;
          } else {
            this.iserror = true;
            this.message = response.data.msg;
          }
          this.loading = false;

          // console.log(this.list);
        });
    },
  },
};
</script>
<style>
.corpusdetaillist-msg .vant-swiper-wrap {
  margin-top: 50px;
}
.corpus-wrap .search-list {
  margin-bottom: 50px;
}
.van-pull-refresh {
  margin-bottom: 50px;
}
.corpusdetail-msg .detail-body .desc-body-box {
  margin-bottom: 50px;
}
.lottery-details {
  background-color: #fff;
}
.lottery-details-body {
  margin-top: 50px;
}
</style>
